import * as React from "react";
import * as ReactRedux from 'react-redux';
import * as Store from "../../../store/Store";
import * as DisplaySettings from "../../../sources/settingsDisplay";



import TaskPage from "../../Pages/TaskPage/TaskPage";

const TaskPageSeedComponent: React.FunctionComponent<{}> = () => {
    const General = ReactRedux.useSelector((state: Store.IAppState) => state.generalState.General)
    const Settings = ReactRedux.useSelector((state: Store.IAppState) => state.settingsState.Settings)
    const ShowProjectLog:boolean = DisplaySettings.default("SHOW_PROJECT_LOG",Settings.defaultSettings);
    const ShowSupportLog:boolean = DisplaySettings.default("SHOW_SUPPORT_LOG",Settings.defaultSettings);

   

    let WebAccessServiceLog: boolean = false; 
    let WebAccessServiceCreateNew: boolean = true; 
    let WebAccessHelpdeskLog: boolean = false; 
    let WebAccessHelpdeskCreateNew: boolean = true; 
    
    if(Settings.contactList){
        let contactList = JSON.parse(Settings.contactList);
        if(contactList.length>1){
            let currentContact: any = contactList.find((item:any) => item.CustomerGuid === General.activeCustomer) ?? {};
            WebAccessServiceLog = (ShowProjectLog && currentContact.WebAccessProjectLog === 1);
            WebAccessHelpdeskLog = (ShowSupportLog && currentContact.WebAccessSupportLog === 1);
            WebAccessServiceCreateNew = (currentContact.WebAccessProject === 1);
            WebAccessHelpdeskCreateNew = (currentContact.WebAccessSupport === 1);
        }else if(contactList.length === 1){
            WebAccessServiceLog = (ShowProjectLog && contactList[0].WebAccessProjectLog === 1);
            WebAccessHelpdeskLog = (ShowSupportLog && contactList[0].WebAccessSupportLog === 1);
            WebAccessServiceCreateNew = (contactList[0].WebAccessProject === 1);
            WebAccessHelpdeskCreateNew = (contactList[0].WebAccessSupport === 1);
        }
    }

    return (
         <TaskPage showServiceLog={WebAccessServiceLog} showHelpdeskLog={WebAccessHelpdeskLog} showServiceCreateNew={WebAccessServiceCreateNew} showHelpdeskCreateNew={WebAccessHelpdeskCreateNew}/>
    )
}

export default TaskPageSeedComponent;