import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import WebFont from 'webfontloader';
import * as ReactRedux from 'react-redux';
import * as ReactI18Next from 'react-i18next';
import * as Redux from 'redux';
import * as Store from "./store/Store";
import configureStore from "./store/Store";
import i18n from "./i18n";
import * as Auth0 from '@auth0/auth0-react';
import { load, loadMessages } from '@progress/kendo-react-intl';


import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';
import usMessages from '../src/assets/translations/en-US.json';

import gbNumbers from 'cldr-numbers-full/main/en-GB/numbers.json';
import gbLocalCurrency from 'cldr-numbers-full/main/en-GB/currencies.json';
import gbCaGregorian from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import gbDateFields from 'cldr-dates-full/main/en-GB/dateFields.json';
import gbMessages from '../src/assets/translations/en-GB.json';

import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';
import deMessages from '../src/assets/translations/de-DE.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import esMessages from '../src/assets/translations/es-ES.json';

import fiNumbers from 'cldr-numbers-full/main/fi/numbers.json';
import fiLocalCurrency from 'cldr-numbers-full/main/fi/currencies.json';
import fiCaGregorian from 'cldr-dates-full/main/fi/ca-gregorian.json';
import fiDateFields from 'cldr-dates-full/main/fi/dateFields.json';
import fiMessages from '../src/assets/translations/fi-FI.json';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import frMessages from '../src/assets/translations/fr-FR.json';

import nbNumbers from 'cldr-numbers-full/main/nb/numbers.json';
import nbLocalCurrency from 'cldr-numbers-full/main/nb/currencies.json';
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json';
import nbMessages from '../src/assets/translations/nb-NO.json';

import noNumbers from 'cldr-numbers-full/main/nb/numbers.json';
import noLocalCurrency from 'cldr-numbers-full/main/nb/currencies.json';
import noCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import noDateFields from 'cldr-dates-full/main/nb/dateFields.json';
import noMessages from '../src/assets/translations/nb-NO.json';

import nlNumbers from 'cldr-numbers-full/main/nl/numbers.json';
import nlLocalCurrency from 'cldr-numbers-full/main/nl/currencies.json';
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json';
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json';
import nlMessages from '../src/assets/translations/nl-NL.json';

import svNumbers from 'cldr-numbers-full/main/sv/numbers.json';
import svLocalCurrency from 'cldr-numbers-full/main/sv/currencies.json';
import svCaGregorian from 'cldr-dates-full/main/sv/ca-gregorian.json';
import svDateFields from 'cldr-dates-full/main/sv/dateFields.json';
import svMessages from '../src/assets/translations/sv-SE.json';

import trNumbers from 'cldr-numbers-full/main/tr/numbers.json';
import trLocalCurrency from 'cldr-numbers-full/main/tr/currencies.json';
import trCaGregorian from 'cldr-dates-full/main/tr/ca-gregorian.json';
import trDateFields from 'cldr-dates-full/main/tr/dateFields.json';
import trMessages from '../src/assets/translations/tr-TR.json';

import daNumbers from 'cldr-numbers-full/main/da/numbers.json';
import daLocalCurrency from 'cldr-numbers-full/main/da/currencies.json';
import daCaGregorian from 'cldr-dates-full/main/da/ca-gregorian.json';
import daDateFields from 'cldr-dates-full/main/da/dateFields.json';
import daMessages from '../src/assets/translations/da-DK.json';

import itNumbers from 'cldr-numbers-full/main/it/numbers.json';
import itLocalCurrency from 'cldr-numbers-full/main/it/currencies.json';
import itCaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itDateFields from 'cldr-dates-full/main/it/dateFields.json';
import itMessages from '../src/assets/translations/it-IT.json';

import plNumbers from 'cldr-numbers-full/main/pl/numbers.json';
import plLocalCurrency from 'cldr-numbers-full/main/pl/currencies.json';
import plCaGregorian from 'cldr-dates-full/main/pl/ca-gregorian.json';
import plDateFields from 'cldr-dates-full/main/pl/dateFields.json';
import plMessages from '../src/assets/translations/pl-PL.json';

loadMessages(deMessages, "de");
loadMessages(gbMessages, "enGB");
loadMessages(esMessages, "es");
loadMessages(fiMessages, "fi");
loadMessages(frMessages, "fr");
loadMessages(noMessages, "nb");
loadMessages(nbMessages, "nb");
loadMessages(nlMessages, "nl");
loadMessages(svMessages, "sv");
loadMessages(trMessages, "tr");
loadMessages(daMessages, "da");
loadMessages(usMessages, "en");
loadMessages(itMessages, "it");
loadMessages(plMessages, "pl");

load(
  likelySubtags,
  currencyData,
  weekData,
  deLocalCurrency,
  deNumbers,
  deCaGregorian,
  deDateFields,
  gbLocalCurrency,
  gbNumbers,
  gbCaGregorian,
  gbDateFields,
  usLocalCurrency,
  usNumbers,
  usCaGregorian,
  usDateFields,
  esLocalCurrency,
  esNumbers,
  esCaGregorian,
  esDateFields,
  fiLocalCurrency,
  fiNumbers,
  fiCaGregorian,
  fiDateFields,
  frLocalCurrency,
  frNumbers,
  frCaGregorian,
  frDateFields,
  nbLocalCurrency,
  nbNumbers,
  nbCaGregorian,
  nbDateFields,
  noLocalCurrency,
  noNumbers,
  noCaGregorian,
  noDateFields,
  nlLocalCurrency,
  nlNumbers,
  nlCaGregorian,
  nlDateFields,
  svLocalCurrency,
  svNumbers,
  svCaGregorian,
  svDateFields,
  trLocalCurrency,
  trNumbers,
  trCaGregorian,
  trDateFields,
  daLocalCurrency,
  daNumbers,
  daCaGregorian,
  daDateFields,
  itLocalCurrency,
  itNumbers,
  itCaGregorian,
  itDateFields,
  plLocalCurrency,
  plNumbers,
  plCaGregorian,
  plDateFields
);

// Create an IProps that would take The store as a prop
interface IProps {
  store: Redux.Store<Store.IAppState>
}

// WebFont is a lib used to load Fonts and applying them easily
WebFont.load({
  google: {
    families: ['Roboto', 'sans-serif']
  }
});

// Create a root component that receives the store via props and wrap the App component with providers passing props to the container components
const Root: React.FunctionComponent<IProps> = props => {
  const { clientId, domain, audience, organizationId } = (window as any)["appConfig"];
  return (
    <Auth0.Auth0Provider
      domain={domain}
      clientId={clientId}
      organization={organizationId ?? undefined}
      redirectUri={window.location.href}
      audience={audience}
      useRefreshTokens={true}
    >
      <ReactRedux.Provider store={props.store}>        
        <React.Suspense fallback="Loading...">
          <ReactI18Next.I18nextProvider i18n={i18n}>
            <App />
          </ReactI18Next.I18nextProvider>
        </React.Suspense>        
      </ReactRedux.Provider>
    </Auth0.Auth0Provider>
  );
};
// Generate the store
const store = configureStore().store;

//Render
ReactDOM.render(
  <Root store={store} />,
  document.getElementById('root') as HTMLElement
);
